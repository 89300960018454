"use client";

export default function ErrorPage({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-background">
      <div className="text-center">
        <h1 className="text-6xl font-bold">{title}</h1>
        <p className="text-muted-foreground text-base">{description}</p>
      </div>
    </div>
  );
}
